@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:400,700');

$primary: #23abe2; /* MAIN COLOR */
$secondary: #484d5b; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #fff;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


body {
	font-size: 1.5em;
  font-family: 'Josefin Sans', sans-serif;

	h1,h2,h3,h4,h5,h6 {
		font-size:2em;
	}
}

nav {
	z-index: 10;
}
.navbar-brand {
    height: 100px; // this is what determine the height of the nav
    padding: 5px;
		@media(max-width: 767px){
			height: 70px; // nav height mobile,(feel free to change this values as you please)
			.logo {
				max-height: 60px; // logo height changed on mobile
			}
		}
}
.navbar .navbar-nav {
> li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: black;
			transition: 1s all ease;
      font-size: 1.2em;


			@media (max-width: 767px) {
				margin-top: 0;
			    display: inline-block;
			    height: 25px;
          padding: 2px 10px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
}
.navbar-toggle {
    margin:20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	margin: 0 auto;
	border-radius: 0px;
	background: transparent;
  border: 1px solid $primary;
	color: $primary;
	padding: 0.8em 1.2em;
	font-size: 1.25em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
  text-decoration: none;

	margin: 2em 0em;
  @media(max-width: 767px){
    font-size: .9em;

  }

	&:hover {
    background: $primary;
    border: 1px solid $wht;
    color: $wht;
    text-decoration: none;
    @media(max-width:1024px){
      background: none;
      border: 1px solid $primary;
      color: $primary;
      text-decoration: none;
    }

	}
}

.btn-default {
	@include btn;
}
// mixins end here


// sections start
section{
  padding: 120px 0;
  @media(max-width:1024px){
    padding: 90px 0;
  }
  @media(max-width:991px){
    padding: 80px 0;
  }
  @media(max-width:767px){
    padding: 60px 0;
  }
  h1 {
    font-size: 3em;
    margin-bottom: 35px;
    @media(max-width: 991px){
      font-size: 2.5em;
    }
    @media(max-width: 767px){
      font-size: 1.7em;
    }
  }
  h2 {
    font-size: 2em;
    margin-bottom: 35px;
    @media(max-width: 767px){
      font-size: 1.7em;
    }
  }
  p {
    font-size: 1.4em;
    margin-bottom: 35px;
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
}
.section-a {
  padding: 200px 0;
  background-color: lighten($blk,70%);
  @media(max-width:1024px){
    padding: 150px 0;
  }
  @media(max-width:991px){
    padding: 100px 0;
  }
  @media(max-width:767px){
    padding: 60px 0;
  }

}
.section-b {
  background-color: lighten($blk,80%);


}
.section-c{
  background-color: lighten($blk,75%);


}
.section-d {
  img {
    margin-bottom: 45px;
    @media(max-width:767px){
      margin-bottom: 20px;
    }
  }

}
.section-e {
  background-color: lighten($blk,75%);
  img {
    margin-bottom: 25px;
  }
  a {
    color: $blk;
  }
}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;

	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}



.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}
